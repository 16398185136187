import { SingleCommunityType, humanize } from '@xborglabs/ui-shared';
import { useCommunityQuests } from '@xborglabs/ui-shared/dist/client';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { Images } from '@/assets/imgs';
import { Card } from '@/modules/shared/components/Atom/Card/Card';
import { GLOBAL_ROUTES } from '@/routes';

import classes from './SingleCommunity.module.scss';

export type SingleCommunityProps = {
  community: SingleCommunityType;
  onClick?: (id: string) => void;
  isMock?: boolean;
};

export const SingleCommunity = ({
  community,
  isMock = false,
}: SingleCommunityProps) => {
  return (
    <Link
      href={`${GLOBAL_ROUTES.COMMUNITY}${community.slug}`}
      className={classes.single_community_container}
      onClickCapture={(e) => {
        if (isMock) e.preventDefault();
      }}
    >
      <Card variant="overlay" spacing="narrow" className={classes.card}>
        <Image
          src={
            community.content?.images.logoImageUrl ?? Images.communityview.logo
          }
          alt={community.name}
          width={138}
          height={138}
          className={classes.image}
        />

        <div className={classes.description_section}>
          <div className={classes.community_name}>{community.name}</div>
          {Boolean(community.content?.description) && (
            <div className={classes.description_text_wrapper}>
              {community.content?.description.map((descriptionItem, index) => (
                <div key={index}>
                  {descriptionItem.children?.map((item, index) => (
                    <p key={index}>{item.text}</p>
                  ))}
                </div>
              ))}
            </div>
          )}
          {!isMock ? <CardFooter community={community} /> : <ComingSoon />}
        </div>
      </Card>
    </Link>
  );
};

function CardFooter({ community }: { community: SingleCommunityType }) {
  const { t } = useTranslation(['communtiy']);
  return (
    <div className={classes.card_footer}>
      {community.activeQuests ? (
        <span className={classes.label}>
          {t('community:questsCount', {
            quests: humanize(community.activeQuests),
          })}
        </span>
      ) : null}
      {
        <span className={classes.label}>
          {t('community:membersCount', {
            members: humanize(community.memberCount),
          })}
        </span>
      }
    </div>
  );
}

function ComingSoon() {
  const { t } = useTranslation(['explore']);
  return <span className={classes.label}>{t('explore:comingSoon')}</span>;
}
