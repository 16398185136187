import {
  CommunityEventList,
  CommunityEventTag,
} from '@xborglabs/ui-shared/dist/client';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { match } from 'ts-pattern';

import { EventsList } from '@/modules/event';
import { TagProps } from '@/modules/shared/components/Atom/Tag';
import { SortGroup } from '@/modules/shared/components/Organism/SortGroup';
import { getActiveTags } from '@/modules/utils';

export type CommunityEventsProps = {
  communityId: string;
};

export const CommunityEvents = ({ communityId }: CommunityEventsProps) => {
  const { t } = useTranslation(['community', 'errors']);
  const [tags, setTags] = useState<TagProps[]>([
    {
      label: t('community:live'),
      slug: 'live',
    },
    {
      label: t('community:upcoming'),
      slug: 'upcoming',
    },
    {
      label: t('community:joined'),
      slug: 'joined',
    },
    {
      label: t('community:closed'),
      slug: 'closed',
    },
  ]);

  return (
    <CommunityEventList
      communityId={communityId}
      tags={getActiveTags(tags) as CommunityEventTag[]}
    >
      {({ events, refetch, status }) => (
        <div className="communityview-events">
          {match(status)
            .with('pending', () => <FiltersSkeleton />)
            .with('success', () => (
              <EventTagWrapper tags={tags} setTags={setTags} />
            ))
            .with('error', () => <></>)
            .exhaustive()}
          <EventsList
            events={events ?? []}
            status={status}
            rejectedMessage={t('errors:globalErrors.events_list_rejected')}
            emptyMessage={t('errors:globalErrors.events_list_empty')}
            onReload={refetch}
          />
        </div>
      )}
    </CommunityEventList>
  );
};

function FiltersSkeleton() {
  return (
    <div className="events-filters">
      <div className="flex middle loading">
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    </div>
  );
}

function EventTagWrapper({
  tags,
  setTags,
  availableTags,
}: {
  tags: TagProps[];
  setTags: (tags: TagProps[]) => void;
  availableTags?: CommunityEventTag[];
}) {
  const { t } = useTranslation(['community', 'errors']);

  useEffect(() => {
    if (!Array.isArray(availableTags)) return;
    if (availableTags.length !== tags.length) return;
    setTags(
      availableTags.map((slug) => ({
        label: t(`community:${slug}`),
        slug,
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableTags]);

  return (
    <div className="events-filters">
      <SortGroup onChange={setTags} list={tags} displayAll />
    </div>
  );
}
